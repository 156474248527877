import React from "react"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import servicesStyles from "../components/services.module.css"

const Cta = props => (
  <div className={servicesStyles.ctaBg}>
    <Container>
      <Row className={servicesStyles.cta}>
        <Col sm className={servicesStyles.ctaDescription}>
          {props.serv ? <h3>Strategic Alliances is a <strong>TRUSTED ADVISOR</strong> and <strong>PARTNER</strong> who will help you achieve your business goals</h3> : <h3>{props.text}</h3>}
        </Col>
        <Col sm className={servicesStyles.ctaButton}>
          <Link to="/#contact" id={servicesStyles.ctaButton}>
            Contact Us
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Cta
