import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import whatStyles from "../components/what.module.css"
import "../components/flaticon/flaticon.css"

import Circle from '../images/circle.svg'
import Pillars from "../components/pillars"
import Cta from "../components/cta"
import Diamond from "../images/diamond.svg"
import Cycle from "../images/cycle.svg"
import Proposition from "../images/proposition.svg"
// import Factory from "../images/factory.svg"
// import Cart from "../images/cart.svg"

const WhatWeDo = () => {
    return (
        <Layout>
            <SEO title="What We Do" />

            <Container className={whatStyles.sectionWhat} id="what-we-do">
                <Row className={whatStyles.main}>
                    <Col>
                        <h1>What We Do</h1>
                    </Col>
                </Row>
                <Row className={whatStyles.rowOne}>
                    <Col lg={4}>
                        <div>
                            <i className="flaticon-respect"></i>
                        </div>
                        <p>We build cohesive teams across organizational boundaries through mutual trust, shared understanding,
clearly defined business intent, mission orientation, disciplined initiative, and prudent acceptance of risk.</p>
                    </Col>
                    <Col lg={4}>
                        <div>
                            <i className="flaticon-team"></i>
                        </div>
                        <p>We lead these teams through mission command allowing these teams to take advantage of fleeting
opportunities to exploit initiative and seize business advantage.</p>
                    </Col>
                    <Col lg={4}>
                        <div>
                            <i className="flaticon-sketch"></i>
                        </div>
                        <p>We design, develop, and deploy the process, technology, and structure enabling subordinate team leaders at
every level across organizational boundaries to exercise initiative and practice the art of command.</p>
                    </Col>
                </Row>
                <Row className={whatStyles.rowTwo}>
                    <Col lg={3}>
                        <div>
                            <i className="flaticon-compass"></i>
                        </div>
                        <p>We build businesses by design – not default.</p>
                    </Col>
                    <Col lg={3}>
                        <div>
                            <i className="flaticon-balance"></i>
                        </div>
                        <p>We balance tactical realities and constraints with strategic mission intent.</p>
                    </Col>
                    <Col lg={3}>
                        <div>
                            <i className="flaticon-idea"></i>
                        </div>
                        <p>We create strategic clarity.</p>
                    </Col>
                    <Col lg={3}>
                        <div>
                            <i className="flaticon-analytics"></i>
                        </div>
                        <p>We deliver profitable business growth.</p>
                    </Col>
                </Row>
                <Row className={whatStyles.rowThree}>

                </Row>

            </Container>
            <Cta text="Delivering value through harmoniously aligned business objectives" />
            <Pillars />
            <Container className={whatStyles.clientValueCycle} id="client-value">
                <Row className={whatStyles.clientValue}>
                    <Col>
                        <h1>Client Value Cycle</h1>
                        <p>Delivering value is an ongoing iterative process that begins with initial introduction to SAI.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src={Cycle} alt="Client Value Cycle" />
                    </Col>
                </Row>
            </Container>
            <div className={whatStyles.approach} id="approach">
                <Container>
                    <Row style={{ marginBottom: "2rem" }}>
                        <Col>
                            <h1>Approach</h1>
                            <p>We deliver profitable growth by leveraging our relationships and operational knowledge of retail organizations to design, develop, and deliver innovative concepts, products, solutions, and services.</p>
                        </Col>
                    </Row>
                    <Row className={whatStyles.approachProcces}>
                        <Col lg={4} className={whatStyles.hideTriangle}>
                            <div className={whatStyles.processOne} id={whatStyles.gradientTwo}>
                                <h3>Retail Expertise</h3>
                                <p>Experts in retail strategy, store operations, demand & supply chain planning, merchandising, and structural & organizational process</p>
                            </div>
                            <div className={whatStyles.triangle}></div>
                            <div className={whatStyles.triangleTwo}></div>

                        </Col>
                        <Col lg={4} className={whatStyles.hideTriangle}>
                            <div className={whatStyles.processOne} id={whatStyles.gradientOne}>
                                <h3>Business Experience & Relevant Business Knowhow</h3>
                                <p>Seasoned retail and CPG professionals that know how to <strong><q>GET THINGS DONE.</q></strong></p>
                            </div>
                            <div className={whatStyles.triangle}></div>
                            <div className={whatStyles.triangleTwo}></div>
                        </Col>
                        <Col lg={4}>
                            <div className={whatStyles.processOne} id={whatStyles.gradientThree}>
                                <h3>Relationships</h3>
                                <p>Broad and deep network of contacts and organizational process.</p>
                            </div>
                            <div className={whatStyles.triangle}></div>
                            <div className={whatStyles.triangleTwo}></div>
                        </Col>
                        <Col xs={12}>
                            <div className={whatStyles.processTwo} id={whatStyles.gradientFour}>
                                <h3>Retail Organization</h3>
                                <ul className={whatStyles.retailOrg}>
                                    <li>Merchandising</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Marketing</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Modular Planning</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Operations</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Logistics</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Sourcing</li>
                                </ul>
                            </div>
                            <div className={whatStyles.triangle}></div>
                            <div className={whatStyles.triangleTwo}></div>
                        </Col>
                        <Col xs={12}>
                            <div className={whatStyles.processThree} id={whatStyles.gradientFive}>
                                <h3>Business Results that are:</h3>
                                <ul className={whatStyles.retailOrg}>
                                    <li>Customer friendly</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Profitable</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>A Strategic value to the client and the Retailer</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>Future-based</li>
                                    <li>
                                        <img src={Circle} alt="Circle" width="12" />
                                    </li>
                                    <li>High quality</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className={whatStyles.valueProposition} id="value-proposition">
                <Row>
                    <Col style={{ marginBottom: "2.5rem" }}>
                        <h1>Value Proposition</h1>
                        <p>Delivering value through harmoniously aligned business objectives.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src={Proposition} alt="Value Proposition" />
                    </Col>
                </Row>
                {/* <Row noGutters>
                    <Col xs={2}>
                        <div class={whatStyles.factory} style={{borderTopLeftRadius: ".25rem", borderBottomLeftRadius: ".25rem"}}>
                            <h3>Supplier</h3>
                            <img src={Factory} alt="Supplier" width="100" />
                        </div>
                    </Col>
                    <Col xs={8}>
                        <Row>
                            <Col xs={12} className={whatStyles.outerValue}>
                                <h4>Business & Relationship Management</h4>
                            </Col>
                            <Col className={whatStyles.upperValue}>
                                <p className={whatStyles.p1}><strong>Develop the Offering</strong></p>
                            </Col>
                            <Col className={whatStyles.upperValue}>
                                <p className={whatStyles.p2}><strong>Acquire the Customer</strong></p>
                            </Col>
                            <Col xs={12} className={whatStyles.centerValue}>
                                <div style={{ width: "40%" }}><p className={whatStyles.p5}><strong>Create the Value</strong></p></div>
                            </Col>
                            <Col className={whatStyles.lowerValue}>
                                <p className={whatStyles.p3}><strong>Procure the Resources</strong></p>
                            </Col>
                            <Col className={whatStyles.lowerValue}>
                                <p className={whatStyles.p4}><strong>Deliver the Offering</strong></p>
                            </Col>
                            <Col xs={12} className={whatStyles.outerValue}>
                                <h4>Project Management</h4>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <div class={whatStyles.factory} style={{borderTopRightRadius: ".25rem", borderBottomRightRadius: ".25rem"}}>
                            <h3>Buyer</h3>
                            <img src={Cart} alt="Buyer" width="100" />
                        </div>
                    </Col>
                </Row> */}
            </Container>
            <Container id="strategic-diamond">
                <Row>
                    <Col md={7} className={whatStyles.strategic}>
                        <div>
                            <h1>The Strategic Business Diamond</h1>
                            <p>We engage all points of the strategic business diamond to ensure we understand total organizational capability and its alignment with strategic objectives.</p>
                        </div>
                    </Col>
                    <Col md={5}>
                        <img src={Diamond} alt="Strategic Business Diamond" />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default WhatWeDo